<template>
  <div
    class="
      widgetContainer
      header-white
      widgetContainer--scrollable
      checkDepositAction
    ">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          {{ $t('RCD_captureScreen_navTitle') }}
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <div class="listWithAmountNew">
        <ul v-if="getCheckDepositList.length !== 0">
          <li v-for="c in getCheckDepositList" :transaction="c" :key="c.id">
            <!-- <div class="icon">
                            <span :class="'icon-'+icon"></span>
                        </div> -->
            <el-tag :class="'el-tag--blue transaction-type type-' + c.status">
              {{ c.status }}
            </el-tag>
            <div class="details">
              <p class="label">
                {{ c.name }}
              </p>
              <p class="value">
                {{ c.transferredAt | date("MMM d, yyyy 'at' h:mm aa") }}
              </p>
            </div>
            <p
              :class="
                'amount ' +
                  (c.status == 'pending'
                    ? 'pending'
                    : c.txnType === 'debit'
                      ? 'minus'
                      : 'plus')
              ">
              {{ c.amount | transactionAmount }}
            </p>
          </li>
        </ul>
        <p class="no-check" v-else>
          {{ $t('RCD_no_check_found') }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
// import TransactionComponent from '@m/transaction/components/Transaction.vue';

import { transferType } from '@m/transaction/enums/index';
export default {
  components: {
    // TransactionComponent
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('checkDeposit', ['getCheckDepositList'])
  },
  methods: {
    ...mapActions('checkDeposit', ['getStatus']),
    getList() {
      const loader = this.showLoader();
      this.getStatus(this.getSelectedAccount.id)
        .then(() => {
        })
        .finally(() => {
          loader.close();
        });
    },
    transferTypeTitle(c) {
      return transferType['titles'][c.transferType];
    }
  }
};
</script>
<style lang="scss">
.checkDepositAction {
  .transaction-type {
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.type-settled {
      color: #2ec193;
      background-color: rgba(46, 193, 147, 0.1);
    }
    &.type-declined {
      color: #ff4a4a;
      background-color: rgba(255, 74, 74, 0.1);
    }
    &.type-pending {
      color: #ffac4a;
      background-color: rgba(255, 172, 74, 0.1);
    }
  }

  .listWithAmountNew {
    ul {
      padding: 10px 0;

      .details {
        .label {
          word-break: normal;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 180px;
        }
      }
    }
  }
  
  .no-check {
    text-align: center;
    color: gray;
  }
}
</style>
